<template>
  <div >
    <v-row class="pl-4 pa-8">
      <v-col cols="12" md="9">
      
        <h2>Ihr Tierarzt-Account: {{ GetUser.user_name }}</h2>

        <v-row class="mt-2">
          <v-col cols="12" md="2">Strasse</v-col>
          <v-col cols="12" md="6"
            >{{ GetUser.user_strasse }} {{ GetUser.user_hsnr }}</v-col
          >
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" md="2">Ort</v-col>
          <v-col cols="12" md="6"
            >{{ GetUser.user_plz }} {{ GetUser.user_ort }}
            <br><router-link to="stammdaten">Adresse ändern</router-link>
            </v-col
          >
        </v-row>
      <v-row class="mt-12" v-if="successBestellung">
          <v-col cols="12" md="9">
            <h2>
             Wir haben Ihre Bestellung erhalten.
              <sup class="redtext">es hat geklappt</sup>
            </h2>
            <p>
              Wir versenden Ihre EisbaumTabellen in 1-2 Werktagen. Dieser Service ist für Sie kostenlos.
            </p>
           
          </v-col>
        </v-row>
        <v-row class="mt-12" v-if="!successBestellung">
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" md="12">
         
            <h2>
              Kostenlos neue EisbaumTabellen bestellen
              <sup class="redtext">kostenlos</sup>
            </h2>
            <p>
              Bestellen Sie jetzt kostenlos neue EisbaumTabellen der besten
              <ul>
                <li>Hunde-Krankenversicherungen </li>
                <li>Katzen-Krankenversicherungen </li>
              </ul>
              für Ihr Wartezimmer. Wir schicken
              Ihnen diese innerhalb von 2-3 Werktagen kostenlos zu.
            </p>
          </v-col>
            </v-row>
            <v-row>
          <v-col cols="12" md="1" class="my-0 py-4">
            <v-checkbox
              v-model="togoChecked"
              class="ma-0"
              @click="addOderEntferneProdukt('EisbaumTabelle ToGo', 'tabelle')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="8">
            <strong style="color: #2196f3"
              >EisbaumTabelle ToGo | Hund & Katze
              <sup class="green--text">Tipp</sup></strong
            >
            <b> | zur Mitnahme</b> <br />T0P 10 Tarife Hund & Katzes - im
            praktischen Postkartenformat. Zur Mitnahme. Ideal in Kombination mit
            WaizmannTabelle Acrylglas-Aufsteller.
          </v-col>
          <v-col cols="12" md="3">
            <img
              :src="require('@/assets/bestellung/togo.png')"
              style="width: 60px; float: left"
            />
          </v-col>
          <v-row class="my-2">
          <v-col cols="12" md="1" class="my-0 py-4">
            <v-checkbox
              class="ma-0"
              @click="addOderEntferneProdukt('Aufsteller ', 'tabelle')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="8">
            <strong style="color: #2196f3"
              >Transparenter Acrylglas-Aufsteller für ToGo Tabellen
            </strong>
            <br />Damit können Sie die ToGo Tabellen in Ihrer Praxis zur
            Mitnahme anbieten. Wenn Sie mehrere Aufsteller (kostenlos)
            benötigen, fordern Sie diesen einfach mehrfach an.
          </v-col>
          <v-col cols="12" md="3">
            <img
              :src="require('@/assets/bestellung/aufsteller.png')"
              style="width: 60px; float: left"
            />
          </v-col>
        </v-row>
        </v-row>
            <v-btn
              type="submit"
              @click="insertBestellung()"
              tile
              :disabled="showButton"
              outlined
              color="#d84315"
              class="nocaps"
              >Jetzt kostenlos neue EisbaumTabellen für Ihre Praxis
              bestellen</v-btn
            >
          </v-col>
        </v-row>

        <v-row class="mt-4" v-if="bestellungen.length > 0">
          <v-col cols="12" md="9">
            <h2>Ihre "Tabellen-Bestellungen"</h2>
            <ul>
              <li v-for="item in bestellungen" :key="item.bestell_id">
            {{ item.bestell_produkt_text }} bestellt am <b>{{ item.bestell_created_date.substr(0,10) }}</b>
  | <b v-if="item.bestell_status == 0" class="orange--text">wird gerade versendet</b>
 <b v-if="item.bestell_status > 0" class="green--text">wird wurde versendet</b>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <menue-download />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenueDownload from "./../base/helper/MenueDownload";
export default {
  name: "index-account",
  components: { MenueDownload },

  data: function () {
    return {
      user: false,
      newBestellung: {
        bestell_type: 1,
        bestell_produkt_text: "",
      },
      togoChecked: true,
      successBestellung: false,
      showButton: false  ,
      bestellungen: false,
      selectbestellung: {
        user_id: 0,
      },
    };
  },
  created() {
    //document.dispatchEvent(new Event("x-app-rendered"));
    this.checkLogin();
    this.addOderEntferneProdukt('EisbaumTabelle ToGo', 'tabelle');
    

  },
  watch: {
    GetUser() {},
  },
 
  computed: {
    GetUser() {
      let user = this.$store.getters.getUserStore;
      return user;
    },
  },

  methods: {
    checkLogin() {
        this.$log.debug("check app ob admin da");
        this.$store.dispatch("apiUserGet", "checklogin").then((resp) => {
          this.$log.debug("checklogin app", resp);
          if (resp.data.user) {
            this.$store.commit("user", resp.data.user);
            this.user = resp.data.user;
             this.getBestellungen();
          }
        });
      },
      addOderEntferneProdukt(titel) {
  // Sicherstellen, dass bestell_produkt_text initialisiert ist
      this.newBestellung.bestell_produkt_text = this.newBestellung.bestell_produkt_text || '';

      let produkte = this.newBestellung.bestell_produkt_text.split(", ").filter(Boolean);
      let index = produkte.indexOf(titel);

      if (index !== -1) {
        // Entfernt den Titel, wenn er bereits vorhanden ist
        produkte.splice(index, 1);
      } else {
        // Fügt den Titel hinzu, wenn er noch nicht vorhanden ist
        produkte.push(titel);
      }

      this.newBestellung.bestell_produkt_text = produkte.join(", ");
},

    insertBestellung() {
  
      this.$store.commit("start_loading_full");
      this.newBestellung.user_id = this.$store.getters.getUserStore.user_id;
      this.newBestellung.action = "insertbestellung";
      this.$store
        .dispatch("apiUserPost", this.newBestellung)
        .then((resp) => {
          if (resp.data) {
            this.successBestellung = true;
            this.$store.commit("stop_loading_full");
            this.getBestellungen();
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getBestellungen() {
      this.selectbestellung.user_id = this.$store.getters.getUserStore.user_id;
      this.selectbestellung.action = "bestellungen";
      this.$log.debug('bestell data',this.selectbestellung);
      this.$store
        .dispatch("apiUserPost", this.selectbestellung)
        .then((resp) => {
          if (resp.data.bestellungen) {
            this.bestellungen = resp.data.bestellungen;
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
  },

  watch: {
  'newBestellung.bestell_produkt_text': function(newValue) {
    if (newValue !== '') {
      this.showButton = false;
    } else {
      this.showButton = true;
    }
  }
}


};
</script> 
